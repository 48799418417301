.hint {
  position: relative;
  top: 100%;
  left: 0;
  font-size: 0.75rem;
  color: #888;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 2px;
  text-decoration: underline;
}

.invalid {
  color: red;
  font-weight: bold;
}

.invalid-button {
  cursor: not-allowed;
}

.valid-button {
  cursor: pointer;
}

.valid-button:hover {
  text-decoration: underline;
}

.custom-dropdown .options {
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
}

.custom-dropdown .options li {
  padding: 8px 12px;
  cursor: pointer;
}

.custom-dropdown .options li:hover {
  background-color: #007bff;
}

.custom-dropdown .selected-option {
  cursor: pointer;
  padding: 8px 12px;
  border: 1px solid #ccc;
  display: inline-block;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-dropdown .dropdown-icon {
  margin-left: 8px;
}

.selected-option {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.options {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  border: 1px solid #ced4da;
  width: 94%;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0.2rem;
  z-index: 1;
}

.options li {
  padding: 8px;
  cursor: pointer;
}

.options li:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.modal-header {
  display: flex;
  align-items: center;
}

.form-field {
  margin-bottom: 1rem;
}

.input-with-icon {
  position: relative;
  width: 100%;
}

input.input-datepicker {
  width: 100%;
  padding-right: 40px;
  box-sizing: border-box;
}

.custom-datepicker-toggle {
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

input.form-control.input-datepicker[readonly] {
  background-color: white;
  color: #495057;
  border: 1px solid #ced4da;
  cursor: pointer;
}

.cdk-overlay-container {
  z-index: 1200 !important;
}

.custom-datepicker .mat-calendar-body-selected {
  background-color: #007bff;
  color: white;
}

.custom-datepicker .mat-calendar-body-cell-hover {
  background-color: #e0e7ff;
  color: #007bff;
}

.custom-datepicker-toggle.mat-datepicker-toggle-active .mat-icon-button {
  color: #007bff !important;
}

.custom-datepicker-toggle .mat-icon-button:focus {
  outline: none;
  background-color: white;
  box-shadow: none;
}

.custom-confirm-button, .custom-cancel-button {
  display: flex; 
  font-weight: normal;
  justify-content: center; 
  align-items: center;
  width: 75px; 
  height: 40px; 
  padding: 0;
  font-size: 16px; 
}

.modal-footer .custom-button {
  min-width: 75px;
}

.required-label {
  font-size: 0.83em;
  font-weight: bold;
}

.asterisk {
  color: red;
  font-weight: bold;
}