.dataTables_filter {
  margin-right: 0;
}

@media (max-width: 999px) {
  td, th {
    white-space: nowrap;
  }
}

table {
  table-layout: fixed;
  display: block;
  width: 100%;
  min-width: 100%;
}

.table-wrapper {
  overflow-x: auto; 
}

.container-fluid table tbody {
  display: block;
  max-height: 479px;
  overflow-y: visible;
}

.scroll-th {
  position: sticky; 
  top: 0; 
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

table th {
  width: 200px; 
}

tbody td {
  width: 150px; 
}

.scroll-th th {
  text-align: center; 
  padding: 10px;
  font-weight: bold;
}

th, td {
  padding: 0.75rem;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.search{
  display: flex; 
  justify-content: flex-end;
  max-height:44px ;
}