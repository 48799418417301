.top {   
  display: flex;
  justify-content: flex-end;
}
 
.btn-container {   
  margin-left: 10px; 
}  
 
@media (max-width: 999px) {
  .container-fluid table th,
  .container-fluid table td {
    white-space: nowrap;
  }
}
 
table {
  table-layout: fixed;
  width: max-content;
  min-width: 100%;
  border-collapse: collapse;
}
 
.table-wrapper {
  overflow-x: auto; 
}
 
.scroll-th {
  position: sticky; 
  top: 0; 
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
 
.scroll-th th {
  text-align: center; 
  padding: 10px;
  font-weight: bold;
}
 
td, th {
  white-space: nowrap;
  text-align: center; 
  padding: 8px; 
  border: none;
}
 
.container-fluid table thead tr,
.container-fluid table tbody tr {
  display: flex;
  width: 100%;
}
 
.container-fluid table th,
.container-fluid table td {
  flex: 1;
  width: 150px;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
table th {
  width: 200px; 
}
 
.container-fluid table tbody {
  display: block;
  width: 100%;
  max-height: 519px;
  overflow-y: visible;
}
 
.search{
  display: flex; 
  justify-content: flex-end;
  max-height:44px ;
}